.wrapper {
	width: 124rem;
	max-width: calc(100% - 6rem);
	margin: 0 auto;

	@include media {
		max-width: calc(100% - 4rem);
	}

	&.narrow {
		width: 120rem;
	}

	&.narrower {
		width: 115rem;
	}
}