%icomoon {
	@include iconStyles();
}

.icon {
	@extend %icomoon !optional;
	@each $name, $glyph in $icomoonIcons {
		&.icon-#{$name}:before {
			@extend %icon-#{$name};
		}
	}
}

@each $name, $glyph in $icomoonIcons {
	%icon-#{$name} {
		content: $glyph;
	}
}