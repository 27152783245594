@import "core/index.scss";
@import 'core';
@import 'normalize';
@import 'icomoon';
@import 'animations';

@import '@wearetla/tla-essentials-tools/styles/grid';
@import 'partials/wrapper';
@import 'partials/label';
@import 'partials/portlet';
@import 'partials/hyperlink';
@import 'partials/wysiwyg';
@import 'partials/table';

// Cookiebot overrides
@import 'sections/cookiebot';

@font-face {
	font-family: 'icomoon';
	src: url('/assets/fonts/icomoon.eot?1');
	src: url('/assets/fonts/icomoon.eot?1#iefix') format('embedded-opentype'),
	url('/assets/fonts/icomoon.woff?1') format('woff'),
	url('/assets/fonts/icomoon.ttf?1') format('truetype'),
		url('/assets/fonts/icomoon.svg?1#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@include fontFace('babymall', '/assets/fonts/subset-AvenirNextLTPro-Regular', $weight-regular);
@include fontFace('Avenir Next', '/assets/fonts/subset-AvenirNextLTPro-Regular', $weight-regular);
@include fontFace('Avenir Next', '/assets/fonts/subset-AvenirNextLTPro-Medium', $weight-medium);
@include fontFace('Avenir Next', '/assets/fonts/subset-AvenirNextLTPro-Demi', $weight-semibold);
@include fontFace('Avenir Next', '/assets/fonts/subset-AvenirNextLTPro-Bold', $weight-bold);

body{
	@include font-main;
	@include font-regular;
	color: $color-text;
	font-size: 1.5rem;
	background-color: $color-white;
	letter-spacing: -.015em;
	opacity: 1;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

html {
	&[class^="block-overflow-"],
	&[class*=" block-overflow-"] {
		overflow: hidden;
		body {
			overflow-y: hidden;
		}

		.scrolltotop {
			opacity: 0;
			pointer-events: none;
		}
	}
}

html {
	font-size: 10px;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;

	@include media(1439) {
		@include calcFontSize(1440);
	}

	@include media {
		@include calcFontSize(375);
	}
}

#root {
	margin: 0 auto;
	overflow: hidden;
}

#site-wrap {
	max-width: 144rem;
	margin: 0 auto;
	
	@include media {
		overflow: auto;
		padding-bottom: calc(6rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom)));
	}
}

// Custom mini classes

.only-mobile {
	@include mediaMin {
		display: none!important;
	}
}

.only-web {
	@include media {
		display: none!important;
	}
}

._hj_feedback_container {
	@include media {
		div[class*="MinimizedWidgetBottom__container"] {
			bottom: 7.5rem!important;
			left: 2rem!important;
			margin-bottom: var(--safe-area-insets-bottom, env(safe-area-inset-bottom))!important;
		}
	}
}