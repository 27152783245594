.table {
	width: 100%;

	th, td {
		border-bottom: .1rem solid $color-gray-light;
	}

	thead {
		th, td {
			padding-bottom: 1rem;
		}
	}

	tbody {
		td, th {
			padding: 2.4rem 0 2.4rem;
			vertical-align: middle;
		}
	}

	th {
		@include font-semibold;
		color: $color-text-light;
	}

	&.valign-top {
		tbody {
			td, th {
				vertical-align: top;
			}
		}
	}
}