.portlet {
	border: .1rem solid $color-gray;
	box-shadow: $shadow-big;
	border-radius: $radius-big;
	padding: 3.8rem 3rem;

	.portlet-section {
		width: calc(100% + 6rem);
		margin: 0 -3rem;
		padding: 3.8rem 3rem;

		&.top {
			margin-top: -3.8rem;
		}

		&.bottom {
			margin-bottom: -3.8rem;
		}
	}
}