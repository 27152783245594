$icomoonIcons: (
	"internet": "\e977",
	"discount-label": "\e974",
	"gesture": "\e973",
	"comment": "\e975",
	"comment-awaiting": "\e976",
	"weight": "\e972",
	"drop-plus-left": "\e96f",
	"drop-plus-right": "\e970",
	"breast-pump": "\e96e",
	"alphabetic": "\e96c",
	"star": "\e96d",
	"share": "\e96b",
	"foot": "\e96a",
	"food": "\e95e",
	"food-2": "\e968",
	"breast-feed": "\e95f",
	"fetus": "\e965",
	"fetus-rounded": "\e960",
	"calendar": "\e961",
	"height": "\e962",
	"time": "\e963",
	"vaccine": "\e964",
	"tooth": "\e966",
	"abc": "\e967",
	"nav": "\e95a",
	"play": "\e959",
	"copy": "\e957",
	"coupon": "\e958",
	"menu-campaigns": "\e952",
	"menu-cart": "\e953",
	"menu-categories": "\e954",
	"menu-home": "\e955",
	"menu-tools": "\e95b",
	"menu-subjects": "\e95c",
	"filter": "\e950",
	"order": "\e951",
	"check-box": "\e94f",
	"return": "\e94e",
	"map": "\e94d",
	"invoice": "\e94c",
	"minus-round": "\e94a",
	"plus-round": "\e94b",
	"gift": "\e949",
	"surprise": "\e945",
	"cargo": "\e946",
	"alert-round": "\e947",
	"alert": "\e948",
	"exclamation": "\e969",
	"pregnant": "\e941",
	"pregnant-2": "\e95d",
	"child": "\e956",
	"boy": "\e942",
	"girl": "\e943",
	"gender": "\e944",
	"frown": "\e93f",
	"alarm": "\e940",
	"photo": "\e93e",
	"trash": "\e93d",
	"logout": "\e936",
	"message": "\e937",
	"address": "\e938",
	"discount": "\e939",
	"payment": "\e93a",
	"box": "\e93b",
	"notification": "\e93c",
	"logo": "\e931",
	"scroll": "\e933",
	"compose": "\e934",
	"help": "\e932",
	"close": "\e935",
	"minus": "\e92f",
	"plus": "\e930",
	"spinner": "\e92e",
	"check": "\e92d",
	"contact": "\e92a",
	"users": "\e92b",
	"secure": "\e92c",
	"measure": "\e925",
	"measure-2": "\e971",
	"comparison": "\e921",
	"price-drop": "\e922",
	"heart-empty": "\e923",
	"heart-full": "\e924",
	"baby-seat": "\e919",
	"trend-up": "\e91a",
	"new": "\e91b",
	"popular": "\e91c",
	"newborn": "\e91d",
	"history": "\e91e",
	"arrow-up": "\e91f",
	"arrow-down": "\e920",
	"arrow-left": "\e917",
	"arrow-right": "\e918",
	"deco": "\e914",
	"deco-num": "\e915",
	"x": "\e978",
	"facebook": "\e929",
	"instagram": "\e916",
	"whatsapp": "\e926",
	"youtube": "\e912",
	"twitter": "\e913",
	"twitter-full": "\e927",
	"callcenter": "\e90e",
	"form": "\e90f",
	"envelope": "\e910",
	"store": "\e911",
	"store-location": "\e928",
	"feature-cargo": "\e90d",
	"feature-original": "\e900",
	"feature-assembly": "\e901",
	"feature-return": "\e902",
	"feature-installment": "\e903",
	"feature-change": "\e904",
	"cart": "\e905",
	"account": "\e906",
	"angle-left": "\e907",
	"angle-right": "\e908",
	"angle-up": "\e909",
	"angle-down": "\e90a",
	"baby": "\e90b",
	"search": "\e90c",
);