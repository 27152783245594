@include media {
  #CybotCookiebotDialog {
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 1)!important;
    top: auto !important;
    bottom: 8px !important;
    transform: translate(-50%, 0) !important;
  }

  #CybotCookiebotDialogBody {
    max-height: 20vh!important;
  }

  #CybotCookiebotDialog *, #CybotCookiebotDialogBodyUnderlay * {
    font-size: 1.2rem!important;
  }

  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    padding: .8rem!important;
  }

  #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyEdgeMoreDetails {
    margin: 1rem 2em .5rem 0!important;
  }
}