@import "core/index.scss";
.round-btn {
	width: 4rem;
	height: 4rem;
	background-color: $color-bg-light;
	color: $color-gray-dark;
	border-radius: 50%;
	padding: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 1.4rem;
	transition: color $transition-hover ease, background-color $transition-hover ease;
	cursor: pointer;
	border: none;

	&:active {
		background-color: $color-gray-light;
		color: $color-text;
	}

	@include mediaMin {
		&:hover {
			background-color: $color-gray-light;
			color: $color-text;
		}
	}
}